import { actions } from "../../../components/grid-views/standart-actions-without-view";

export const columns = [
  {
    label: 'Email',
    field: 'email',
    width: '45%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'Credentials',
    field: 'credentials',
    width: '55%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
]

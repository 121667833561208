<template>
  <div>
    <crud-form
      :schema="schema"
      :model-id="id"
      :path="'mail-group'"
      :form-title="$t('editMailGroup')"
      :redirect-route="'MailGroupsList'"
    />
    <div class="container">
      <b-card>
        <div slot="header">
          <strong>{{ $t('subscribers') }}</strong>
        </div>
        <grid-view-table
          :id="'mail-group-subscribers-'+id"
          :columns="columns"
          :path="'mail-subscriber'"
          :extra-params="'&join=groups&filter=groups.id||$eq||'+id"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import GridViewTable from '../../components/GridViewTable';
  import { schema } from './schema/group';
  import { columns } from './grid-views/group-subscribers';

  export default {
    name: 'UpdateMailGroup',
    components: {
      CrudForm,
      GridViewTable,
    },
    props: ['id'],
    data: () => {
      return {
        schema,
        columns,
      }
    },
  }
</script>
